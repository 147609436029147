import React, { createContext } from 'react'
import { Paragraph } from '../../../buildingBlocks/Text/Paragraph'
import { ReusablePuzzle } from './ReusablePuzzle'

export const PlayerContext = createContext()

export const ReusablePuzzleContainer = ({ player, puzzleIndex }) => {
    return (
        <>
            <Paragraph fontSize='2rem' block>
                {player}
            </Paragraph>
            <PlayerContext.Provider value={player}>
                <ReusablePuzzle puzzleIndex={puzzleIndex} />
            </PlayerContext.Provider>
        </>
    )
}
