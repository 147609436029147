export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const ADD_5 = 'ADD_5'
export const SUBTRACT_5 = 'SUBTRACT_5'
export const DELETE_SNAPSHOT = 'DELETE_SNAPSHOT'
export const STORE_STATE = 'STORE_STATE'

export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const UPDATE_USERNAME = 'UPDATE_USERNAME'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_CONFIRMATION_EMAIL = 'RESET_PASSWORD_CONFIRMATION_EMAIL'
export const UPDATE_WAS_REDIRECTED = 'UPDATE_WAS_REDIRECTED'

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const FLIP_PIECE = 'FLIP_PIECE'
export const SET_PUZZLE_STATE = 'SET_PUZZLE_STATE'
export const SET_PUZZLE_SIZE = 'SET_PUZZLE_SIZE'
export const SET_PUZZLE_DIFFICULTY = 'SET_PUZZLE_DIFFICULTY'
export const RESET_PUZZLE = 'RESET_PUZZLE'
export const SET_WAS_TOUCHED = 'SET_WAS_TOUCHED'
export const SET_COMPLETED = 'SET_COMPLETED'
export const INITIALIZE_SCORECARD = 'INITIALIZE_SCORECARD'
export const UPDATE_SCORECARD = 'UPDATE_SCORECARD'
export const UPDATE_SHOW_ONBOARDING = 'UPDATE_SHOW_ONBOARDING'
