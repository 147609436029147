import React, { useContext } from 'react'
import { Col, Row, Form, ButtonGroup, Button } from 'react-bootstrap'
import { ThemeContext } from '../../App'

export const PuzzlePicker = ({ size, setSize, difficulty, setDifficulty }) => {
    const customSpacingSmall = {
        marginBottom: '0px',
    }

    return (
        <Col>
            <Row>
                <Col sm='10'>
                    <SizeSelector size={size} setSize={setSize} />
                </Col>
                <Col sm='2'></Col>
            </Row>
            <div style={customSpacingSmall}></div>
            <Row>
                <Col sm='10'>
                    <DifficultySelector difficulty={difficulty} setDifficulty={setDifficulty} size={size} />
                </Col>
                <Col sm='2'></Col>
            </Row>
        </Col>
    )
}

const SizeSelector = ({ size, setSize }) => {
    const theme = useContext(ThemeContext)

    const generateButtonConfigs = (sizeRange) => {
        const buttonConfig = []

        for (let i = sizeRange[0]; i < sizeRange[1] + 1; i++) {
            buttonConfig.push({
                actualLevel: i + 2,
                displayLevel: i,
            })
        }

        return buttonConfig
    }

    const buttonConfigs = generateButtonConfigs([1, 8])

    const ConfiguredButton = ({ config }) => {
        return (
            <Button
                active={config.actualLevel === size}
                onClick={() => setSize(config.actualLevel)}
                variant={'primary'}>
                {config.displayLevel}
            </Button>
        )
    }

    return (
        <Form>
            <Form.Group as={Row}>
                <Form.Label column sm='3'>
                    <strong>
                        <span className={'navlinks_' + theme.theme}>level</span>
                    </strong>
                </Form.Label>
                <Col sm='9'>
                    <ButtonGroup>
                        {buttonConfigs.map((config) => (
                            <ConfiguredButton config={config} />
                        ))}
                    </ButtonGroup>
                </Col>
            </Form.Group>
        </Form>
    )
}

const DifficultySelector = ({ difficulty, setDifficulty, size }) => {
    const theme = useContext(ThemeContext)

    const difficultyButtonConfigs = generateDifficultyButtonConfigs(getDifficultyBoundaries(size))

    const ConfiguredDifficultyButton = ({ config }) => {
        return (
            <Button
                onClick={() => setDifficulty(config.difficultyAbbreviated)}
                active={difficulty === config.difficultyAbbreviated}
                variant={'primary'}>
                {config.difficulty}
            </Button>
        )
    }

    return (
        <Form>
            <Form.Group as={Row}>
                <Form.Label column sm='3'>
                    <strong>
                        <span className={'navlinks_' + theme.theme}>difficulty</span>
                    </strong>
                </Form.Label>
                <Col sm='9'>
                    <ButtonGroup>
                        {difficultyButtonConfigs.map((config) => (
                            <ConfiguredDifficultyButton config={config} />
                        ))}
                    </ButtonGroup>
                </Col>
            </Form.Group>
        </Form>
    )
}

const generateDifficultyButtonConfigs = (difficultyRange) => {
    const diffIndex = { easy: 1, med: 2, hard: 3, vhard: 4, xhard: 5 }
    const diffIndexInverse = { 1: 'easy', 2: 'medium', 3: 'hard', 4: 'very hard', 5: '☠️' }
    const diffIndexInverseAbbreviated = { 1: 'easy', 2: 'med', 3: 'hard', 4: 'vhard', 5: 'xhard' }

    const difficultyButtonConfigs = []

    for (let i = diffIndex[difficultyRange[0]]; i < diffIndex[difficultyRange[1]] + 1; i++) {
        difficultyButtonConfigs.push({
            difficulty: diffIndexInverse[i],
            difficultyAbbreviated: diffIndexInverseAbbreviated[i],
        })
    }

    return difficultyButtonConfigs
}

const getDifficultyBoundaries = (size) => {
    const normalizedSize = size - 3

    const availableDifficulties = [
        ['easy', 'med', 'hard'],
        ['easy', 'med', 'hard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard', 'xhard'],
        ['easy', 'med', 'hard', 'vhard', 'xhard'],
    ]

    const difficultiesAvailableAtSize = availableDifficulties[normalizedSize]

    return [difficultiesAvailableAtSize[0], difficultiesAvailableAtSize[difficultiesAvailableAtSize.length - 1]]
}
