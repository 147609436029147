import React, { useContext } from 'react'
import ReactCardFlip from 'react-card-flip'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'
// eslint-disable-next-line
import classes from './Piece.css'
import { ThemeContext } from '../../../App'

const Piece = ({ puzzleState, props, flipPiece, puzzleSize }) => {
    const theme = useContext(ThemeContext)

    const handleFlip = () => {
        flipPiece(puzzleState, props.index, puzzleSize)
        props.incrementMovesMade()
    }

    return (
        <ReactCardFlip className='anotherClass' isFlipped={puzzleState[props.index]} flipDirection='vertical'>
            <div className={'side-one_' + theme.theme + ' puzzle-piece isClickable'} onClick={handleFlip}></div>
            <div className={'side-two_' + theme.theme + ' puzzle-piece isClickable'} onClick={handleFlip}></div>
        </ReactCardFlip>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        puzzleState: state.puzzle.puzzleState,
        puzzleSize: state.puzzle.puzzleSize,
        props: ownProps,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        flipPiece: (state, index, size) => dispatch(actions.flipPiece(state, index, size)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Piece)
