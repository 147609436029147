import React, { useState, useContext } from 'react'
import { range } from '../../../utils/iteration/python'
import { getNeighbours } from './utils/helpers'
import { OnlineGamePuzzleStateContext, OnlineGameSendMoveContext } from '../../Multiplayer/OnlineGame'
import { OnlineGameReusablePiece } from './OnlineGameReusablePiece'

/** Only need to pass in the puzzle state and the puzzle of the right size will get rendered */
export const OnlineGameReusablePuzzle = ({ puzzleIndex }) => {
    const [puzzleState, setPuzzleState] = React.useContext(OnlineGamePuzzleStateContext)[puzzleIndex]
    const [puzzleSize] = useState(Math.sqrt(puzzleState.length))
    const sendMove = useContext(OnlineGameSendMoveContext)

    /**Table Styles */
    const tableStyle = {
        boarder: '2px solid black',
        width: '100%',
    }

    const tdStyle = {
        position: 'relative',
        width: `${100 / puzzleSize}%`,
        height: `${100 / puzzleSize}%`,
        paddingTop: `${100 / puzzleSize}%`,
    }

    const handlePieceFlip = (index) => {
        const postMovePuzzleState = [...puzzleState]
        const neighbours = getNeighbours(Number(puzzleSize), puzzleState, index)
        neighbours.push(index)
        for (let idxToFlip of neighbours) {
            flipIndividualPiece(idxToFlip, postMovePuzzleState)
        }
        const newState = postMovePuzzleState.join('')
        sendMove(newState)
        setPuzzleState(newState)
    }

    const flipIndividualPiece = (i, newPuzzleState) => {
        newPuzzleState[i] = newPuzzleState[i] === '1' ? '0' : '1'
    }

    const tableData = (i) => {
        return (
            <td style={tdStyle} key={i}>
                <OnlineGameReusablePiece state={puzzleState} handlePieceFlip={handlePieceFlip} index={i} key={i} />
            </td>
        )
    }

    const tableRow = (i) => {
        const tableColumns = range(puzzleSize)
            .map((el) => tableData(el + i * puzzleSize))
            .reduce((accumulator, el) => accumulator.concat(el), [])

        return <tr key={i}>{tableColumns}</tr>
    }

    const tableRows = range(puzzleSize)
        .map((i) => tableRow(i))
        .reduce((accumulator, el) => accumulator.concat(el), [])

    const table = (
        <table style={tableStyle}>
            <tbody>{tableRows}</tbody>
        </table>
    )

    return table
}
