import React, { useContext } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import AccountNavigation from '../AccountNavigation/AccountNavigation'
import { LinkContainer } from 'react-router-bootstrap'
import { ThemeContext } from '../../../App'
import { ThemeChanger } from '../ThemeChanger/ThemeChanger'

const Layout = (props) => {
    const theme = useContext(ThemeContext)
    const showThemeChanger = false
    const showMultiplayer = true

    return (
        <div className='root-container'>
            <Navbar expand='md' collapseOnSelect={true}>
                <LinkContainer to='/'>
                    <Navbar.Brand href='#home'>
                        <span className={'navlinks_' + theme.theme + ' hvr-underline-from-left'}>²Solve</span>
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='mr-auto'>
                        <LinkContainer to='/puzzle'>
                            <Nav.Link>
                                <span
                                    className={'navlinks_' + theme.theme + ' hvr-underline-from-left'}
                                    style={{ marginBottom: '-4px' }}>
                                    puzzle
                                </span>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to='/leaderboards'>
                            <Nav.Link>
                                <span
                                    className={'navlinks_' + theme.theme + ' hvr-underline-from-left'}
                                    style={{ marginBottom: '-4px' }}>
                                    leaderboards
                                </span>
                            </Nav.Link>
                        </LinkContainer>
                        {showMultiplayer && props.isLoggedIn && (
                            <LinkContainer to='/multiplayer'>
                                <Nav.Link>
                                    <span
                                        className={'navlinks_' + theme.theme + ' hvr-underline-from-left'}
                                        style={{ marginBottom: '-4px' }}>
                                        multiplayer
                                    </span>
                                </Nav.Link>
                            </LinkContainer>
                        )}
                    </Nav>
                    {showThemeChanger && <ThemeChanger />}
                    <AccountNavigation isLoggedIn={props.isLoggedIn} />
                </Navbar.Collapse>
            </Navbar>
            {props.children}
        </div>
    )
}

export default Layout
