import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Alert, Image, Button } from 'react-bootstrap'
import solving_demo from './static_files/solving_demo.gif'
import { LinkContainer } from 'react-router-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as actions from '../../store/actions/index'
import { ThemeContext } from '../../App'

const Home = ({ isAuthenticated, wasRedirected, updateWasRedirected }) => {
    const history = useHistory()
    const [showOnboarding, setShowOnboarding] = useState(true)
    const theme = useContext(ThemeContext)

    useEffect(() => {
        if (isAuthenticated && !wasRedirected) {
            updateWasRedirected(true)
            history.push('/puzzle')
        }
    }, [isAuthenticated, history, updateWasRedirected, wasRedirected])

    const customSpacing = {
        marginBottom: '20px',
    }
    const customSpacingLarge = {
        marginBottom: '4%',
    }

    return (
        <div>
            <div style={customSpacing}></div>
            <Row>
                <Col sm='1'></Col>
                <Col sm='10'>
                    <Row>
                        <Col sm='2'></Col>
                        <Col sm='8'>
                            <Alert
                                className={'alert-warning_' + theme.theme}
                                show={showOnboarding}
                                variant='primary'
                                onClose={() => setShowOnboarding(false)}
                                dismissible>
                                <Alert.Heading>
                                    <span role='img' aria-label='hand-wave emoji'>
                                        👋{' '}
                                    </span>
                                    welcome to square solve{' '}
                                </Alert.Heading>
                                <p>
                                    Solve the puzzle by turning all the pieces pink. Flipping a piece, will also flip
                                    all adjacent pieces.
                                </p>
                            </Alert>
                        </Col>
                        <Col sm='2'></Col>
                    </Row>
                </Col>
                <Col sm='1'></Col>
            </Row>
            <div style={customSpacing}></div>
            <Row>
                <Col sm='1'></Col>
                <Col sm='10'>
                    <Row>
                        <Col sm='2'></Col>
                        <Col sm='8'>
                            <Image fluid src={solving_demo}></Image>
                            <div style={customSpacingLarge}></div>
                            <LinkContainer to='/puzzle' style={{ float: 'right' }}>
                                <Button size='lg' variant='success'>
                                    start playing{' '}
                                    <span role='img' aria-label='finger pointing up emoji'>
                                        👆
                                    </span>
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col sm='2'></Col>
                    </Row>
                </Col>
                <Col sm='1'></Col>
            </Row>
            <div style={customSpacingLarge}></div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        wasRedirected: state.auth.wasRedirected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateWasRedirected: (wasRedirected) => {
            dispatch(actions.updateWasRedirected(wasRedirected))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
