import * as actionTypes from './actionTypes'
import { range } from '../../utils/iteration/python'
import { firestoreUpdateCompletedPuzzle, updateUserShowOnboarding, setUserScore } from '../../firebase/tdg'

export const flipPiece = (state, index, size) => {
    return (dispatch) => {
        const neighbours = getNeighbours(Number(size), state, index)
        neighbours.push(index)
        neighbours.forEach((idx) => {
            dispatch(flipIndex(idx))
        })
    }
}

export const flipIndex = (index) => {
    return {
        type: actionTypes.FLIP_PIECE,
        index: index,
    }
}

export const setPuzzleState = (state) => {
    return {
        type: actionTypes.SET_PUZZLE_STATE,
        newState: state,
    }
}

export const setPuzzleSize = (size) => {
    return {
        type: actionTypes.SET_PUZZLE_SIZE,
        size: size,
    }
}

export const setPuzzleDifficulty = (difficulty) => {
    return {
        type: actionTypes.SET_PUZZLE_DIFFICULTY,
        difficulty: difficulty,
    }
}

export const changePuzzleSize = (size) => {
    return (dispatch) => {
        dispatch(setPuzzleSize(size))
    }
}

export const resetPuzzle = (puzzleSize) => {
    const newState = range(Math.pow(puzzleSize, 2)).map(() => false)
    return setPuzzleState(newState)
}

export const setWasTouched = (wasTouched) => {
    return {
        type: actionTypes.SET_WAS_TOUCHED,
        wasTouched: wasTouched,
    }
}

export const updateCompletedPuzzle = (size, difficulty, email, username, movesToSolve, isAuthenticated) => {
    return (dispatch) => {
        if (isAuthenticated) {
            firestoreUpdateCompletedPuzzle(email, size, difficulty)
            setUserScore(size, difficulty, username, movesToSolve)
        }
        dispatch(updateCompletedPuzzleLocal(size, difficulty))
    }
}

const updateCompletedPuzzleLocal = (size, difficulty) => {
    return {
        type: actionTypes.SET_COMPLETED,
        size: size,
        difficulty: difficulty,
    }
}

export const initializeScorecard = () => {
    return {
        type: actionTypes.INITIALIZE_SCORECARD,
    }
}

export const updateScorecard = (newScorecard) => {
    return {
        type: actionTypes.UPDATE_SCORECARD,
        scorecard: newScorecard,
    }
}

export const updateShowOnboarding = (email, showOnboarding) => {
    return (dispatch) => {
        dispatch(updateLocalShowOnboarding(showOnboarding))
        updateUserShowOnboarding(email, showOnboarding)
    }
}

export const updateLocalShowOnboarding = (showOnboarding) => {
    return {
        type: actionTypes.UPDATE_SHOW_ONBOARDING,
        showOnboarding: showOnboarding,
    }
}

const getNeighbours = (size, state, target) => {
    let tentative_neighbours = []
    if (target % size === 0) {
        tentative_neighbours = [target - size, target + size, target + 1]
    } else if (target % size === size - 1) {
        tentative_neighbours = [target - size, target + size, target - 1]
    } else {
        tentative_neighbours = [target + 1, target - 1, target + size, target - size]
    }

    let correct_neighbours = []
    tentative_neighbours.forEach((el) => {
        if (0 <= el && el < state.length) {
            correct_neighbours.push(el)
        }
    })

    return correct_neighbours
}
