import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { ThemeContext } from '../../App'
import { useHistory } from 'react-router-dom'

export const Multiplayer = () => {
    const theme = useContext(ThemeContext)
    const history = useHistory()

    return (
        <>
            <Row>
                <Col sm='3'></Col>
                <Col sm='6'>
                    <h1 className={'navlinks_' + theme.theme}>multiplayer</h1>
                </Col>
                <Col sm='3'></Col>
            </Row>
            <Row>
                <Col sm='3'></Col>
                <Col sm='6'>
                    <Button style={{ marginRight: '10px' }} onClick={() => history.push('/multiplayer/privateGame')}>
                        Private Game
                    </Button>
                    <Button onClick={() => history.push('/multiplayer/publicGame')}>Public Game</Button>
                </Col>
                <Col sm='3'></Col>
            </Row>
        </>
    )
}
