import React, { useContext } from 'react'
import ReactCardFlip from 'react-card-flip'
// eslint-disable-next-line
import classes from './Piece.css'
import { ThemeContext } from '../../../App'
import { PlayerContext } from './ReusablePuzzleContainer'

export const ReusablePiece = (props) => {
    const theme = useContext(ThemeContext)
    const player = useContext(PlayerContext)

    let className = theme.theme + ' puzzle-piece'
    if (player === 'you') {
        className = className.concat(' isClickable')
    }

    const handleClick = player === 'you' ? () => props.handlePieceFlip(props.index) : undefined

    return (
        <ReactCardFlip className='anotherClass' isFlipped={props.state[props.index] === '1'} flipDirection='vertical'>
            <div className={'side-one_' + className} onClick={handleClick}></div>
            <div className={'side-two_' + className} onClick={handleClick}></div>
        </ReactCardFlip>
    )
}
