import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import styled from 'styled-components'
import { theme } from '../index'

// let COLORS = {
//     COLOR1: '#e14594',
//     COLOR2: '#2b3595',
//     COLOR3: '#182952',
//     COLOR4: '#222A77',
//     COLOR5: '#7045af',
//     COLOR6: '#5a378c',
// }

// export const theme = {
//     primary: COLORS.COLOR1,
//     secondary: COLORS.COLOR2,
//     background: COLORS.COLOR3,
//     button: {
//         main: COLORS.COLOR2,
//         mainHover: COLORS.COLOR4,
//         completed: COLORS.COLOR5,
//         completedHover: COLORS.COLOR6,
//     },
// }

// const setTheme = (paletteNumber) => {
//     COLORS = PALETTES[`PALETTE${paletteNumber}`]
//     console.log(COLORS)
// }

export const ThemeChangeButton = ({ theme: themeNumber, children }) => {
    return (
        <StyledThemeButton onClick={() => theme.setTheme(themeNumber)} size='sm'>
            {children}
        </StyledThemeButton>
    )
}

const StyledThemeButton = styled(BootstrapButton)`
    background-color: ${(props) => {
        console.log(props.theme.primary)
        return props.theme.primary
    }};
    &:hover {
        background-color: purple;
    }
`
