const PUZZLE_CONFIGS = [
    ['easy', 'med', 'hard'],
    ['easy', 'med', 'hard'],
    ['easy', 'med', 'hard', 'vhard'],
    ['easy', 'med', 'hard', 'vhard'],
    ['easy', 'med', 'hard', 'vhard'],
    ['easy', 'med', 'hard', 'vhard'],
    ['easy', 'med', 'hard', 'vhard', 'xhard'],
    ['easy', 'med', 'hard', 'vhard', 'xhard'],
]

export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues,
    }
}

export const flipPiece = (state, action) => {
    let newPuzzleState = [...state.puzzleState]
    newPuzzleState[action.index] = !state.puzzleState[action.index]
    return updateObject(state, {
        puzzleState: newPuzzleState,
        wasTouched: true,
    })
}

export const setCompleted = (state, action) => {
    let oldScorecard = { ...state.scorecard }
    oldScorecard[action.size][action.difficulty] = true
    return updateObject(state, oldScorecard)
}

export const getNewScorecard = () => {
    return {
        3: {
            easy: false,
            med: false,
            hard: false,
        },
        4: {
            easy: false,
            med: false,
            hard: false,
        },
        5: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        6: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        7: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        8: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        9: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
            xhard: false,
        },
        10: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
            xhard: false,
        },
    }
}

export const getNextPuzzleInLine = (scorecard) => {
    const PUZZLE_CONFIGS = [
        ['easy', 'med', 'hard'],
        ['easy', 'med', 'hard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard'],
        ['easy', 'med', 'hard', 'vhard', 'xhard'],
        ['easy', 'med', 'hard', 'vhard', 'xhard'],
    ]

    for (let i = 0; i <= 7; i++) {
        for (let j = 0; j < PUZZLE_CONFIGS[i].length; j++) {
            let difficulty = PUZZLE_CONFIGS[i][j]
            if (!scorecard[i + 3][difficulty]) {
                return { size: i + 3, difficulty: difficulty }
            }
        }
    }
    return { size: 10, difficulty: 'xhard' }
}

export const getNextDifficulty = (scorecard, size) => {
    const zeroBasedSize = size - 3

    for (let i = 0; i < PUZZLE_CONFIGS[zeroBasedSize].length; i++) {
        if (!scorecard[size][PUZZLE_CONFIGS[zeroBasedSize][i]]) {
            return PUZZLE_CONFIGS[zeroBasedSize][i]
        }
    }
    return 'easy'
}

export const getInitialPuzzleState = (size, difficulty) => {
    const initial_states = {
        3: {
            easy: '000001011',
            med: '011000011',
            hard: '110000101',
        },
        4: {
            easy: '0000000100110001',
            med: '0000100111111001',
            hard: '0000001101100100',
        },
        5: {
            easy: '1010010100010000000000000',
            med: '0000000100111100110001000',
            hard: '0000000000000010110110100',
            vhard: '0011110101100111011100101',
        },
        6: {
            easy: '010100011110100100000000000000000000',
            med: '100000110000110000011000100000100000',
            hard: '000000100000110110100001011111000101',
            vhard: '011100001011000000011100111111010001',
        },
        7: {
            easy: '0000010000001000010010001100011000000100000000000',
            med: '0000000000000010000100100101010011110000010001100',
            hard: '0001100011100000011100011111011111000111100000100',
            vhard: '1110000010011101000101111001001111100001100010100',
        },
        8: {
            easy: '0000000000000000000100010011101100010001011010001010110000011000',
            med: '0010001101110001001000000011000001011100000001100001010000000000',
            hard: '1111110000001000110000010011001100111011001000110001101000000000',
            vhard: '1011010010111111001111110001000110001010010110010100110110000010',
        },
        9: {
            easy: '000000000010110000110001000010110000000000000100000000110010000110111000111010000',
            med: '001110000000100000000100001101110011110000001101110010000100100000000110000000011',
            hard: '001110000000100000000100001101110011110000001101110010000100100000000110000000011',
            vhard: '010110010100001111101110110000001110111000101010001011100111101110111001111000011',
            xhard: '001111000100101110110011011100100010011110111011011110110101011010101110111011000',
        },
        10: {
            easy:
                '0001111000100011110001000010000100000000110000000011100000000100000000000000000000000000000000000000',
            med: '0000000000000000000000010000000011100000000110000001111100001001100000011000010000011011100010010100',
            hard:
                '0000000011000000010110100001111011011011101000000100010110010011100101000100010100000000000000000000',
            vhard:
                '0001010000011101101011111101110001110010110010000000110000001000100010101011011010101000010000000001',
            xhard:
                '0000000000100000101111000010011101010001111001011101000101001011010001011010101110011101011100110111',
        },
    }
    return initial_states[size][difficulty]
}
