import React, { useEffect } from 'react'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import * as yup from 'yup'

const initialValues = { email: '' }
const validationSchema = yup.object().shape({
    email: yup.string().email().required('Please enter an email'),
})

const ResetPassword = ({ onResetPassword, resetEmail, ownProps, setResetPasswordConfirmation }) => {
    useEffect(() => {
        if (resetEmail !== null) {
            ownProps.history.push('/auth')
            setResetPasswordConfirmation(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetEmail])

    return (
        <div className='horizontal-center'>
            <div className='vertical-center'>
                <Formik
                    validationSchema={validationSchema}
                    onSubmit={(event) => onResetPassword(event.email)}
                    initialValues={initialValues}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit} className='centered-form'>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    size='sm'
                                    type='text'
                                    name='email'
                                    placeholder='Email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && errors.email}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Button size='sm' type='submit' variant='outline-primary'>
                                Send reset email
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        resetEmail: state.auth.resetPasswordConfirmation,
        ownProps: ownProps,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onResetPassword: (email) => dispatch(actions.resetPassword(email)),
        setResetPasswordConfirmation: (email) => dispatch(actions.resetPasswordConfirmation(email)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
