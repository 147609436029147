import React from 'react'
import { ButtonGroup } from 'react-bootstrap'
import PuzzleDifficultyButton from './PuzzleDifficultyButton'

const PuzzleDifficultySelector = ({ puzzleSize, handleConfirmGeneratePuzzle }) => {
    return (
        <ButtonGroup>
            <PuzzleDifficultyButton
                handleConfirmGeneratePuzzle={handleConfirmGeneratePuzzle}
                targetPuzzleSize={'easy'}
                buttonLabel={'easy'}
            />
            <PuzzleDifficultyButton
                handleConfirmGeneratePuzzle={handleConfirmGeneratePuzzle}
                targetPuzzleSize={'med'}
                buttonLabel={'medium'}
            />
            <PuzzleDifficultyButton
                handleConfirmGeneratePuzzle={handleConfirmGeneratePuzzle}
                targetPuzzleSize={'hard'}
                buttonLabel={'hard'}
            />
            {puzzleSize > 4 && (
                <PuzzleDifficultyButton
                    handleConfirmGeneratePuzzle={handleConfirmGeneratePuzzle}
                    targetPuzzleSize={'vhard'}
                    buttonLabel={'very hard'}
                />
            )}
            {puzzleSize > 8 && (
                <PuzzleDifficultyButton
                    handleConfirmGeneratePuzzle={handleConfirmGeneratePuzzle}
                    targetPuzzleSize={'xhard'}
                    buttonLabel={'☠️'}
                />
            )}
        </ButtonGroup>
    )
}

export { PuzzleDifficultySelector }
