import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import SizeSelector from '../Puzzle/Components/SizeSelector'
import { getLeaderboard } from '../../firebase/tdg'
import LeaderboardsTable from './LeaderboardsTable'
import { getNewScorecard } from '../../store/utility'
import LoadingAnimation from '../../buildingBlocks/Loading/LoadingAnimation'
import { ThemeContext } from '../../App'

const Leaderboards = ({ scorecard, email, username, isAuthenticated }) => {
    const theme = useContext(ThemeContext)

    const customSpacingLarge = {
        marginBottom: '4%',
    }

    const [level, setLevel] = useState(3)
    const [difficulty, setDifficulty] = useState('easy')

    const [isLoading, setIsLoading] = useState(false)
    const [records, setRecords] = useState([])

    const handleChangeLevel = (level) => {
        setDifficulty('easy')
        setLevel(level)
    }

    const blankScorecard = getNewScorecard()

    const generateEasyPuzzleButton = (
        <Button onClick={() => setDifficulty('easy')} active={difficulty === 'easy'}>
            easy
        </Button>
    )

    const generateMediumPuzzleButton = (
        <Button onClick={() => setDifficulty('med')} active={difficulty === 'med'}>
            medium
        </Button>
    )

    const generateHardPuzzleButton = (
        <Button onClick={() => setDifficulty('hard')} active={difficulty === 'hard'}>
            hard
        </Button>
    )

    const generateVeryHardPuzzleButton = (
        <Button onClick={() => setDifficulty('vhard')} active={difficulty === 'vhard'}>
            very hard
        </Button>
    )

    const generateImpossiblePuzzleButton = (
        <Button onClick={() => setDifficulty('xhard')} active={difficulty === 'xhard'}>
            <span role='img' aria-label='skull emoji'>
                ☠️
            </span>
        </Button>
    )

    const customSpacing = {
        marginBottom: '20px',
    }

    const customSpacingSmall = {
        marginBottom: '0px',
    }

    useEffect(() => {
        setIsLoading(true)
        getLeaderboard(level, difficulty).then((result) => {
            setRecords(result)
            setIsLoading(false)
        })
    }, [level, difficulty])

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col sm='3'></Col>
                    <Col sm='6'>
                        <h1 className={'navlinks_' + theme.theme}>leaderboards</h1>
                    </Col>
                    <Col sm='3'></Col>
                </Row>
                <div style={customSpacingLarge}></div>
                <Row>
                    <Col sm='3'></Col>
                    <Col sm='6'>
                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column sm='3'>
                                    <strong className={'navlinks_' + theme.theme}>level</strong>
                                </Form.Label>
                                <Col sm='9'>
                                    <SizeSelector
                                        selectedSize={level}
                                        scorecard={blankScorecard}
                                        handleClick={handleChangeLevel}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col sm='3'></Col>
                </Row>
                <div style={customSpacingSmall}></div>
                <Row>
                    <Col sm='3'></Col>
                    <Col sm='6'>
                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column sm='3'>
                                    <strong className={'navlinks_' + theme.theme}>difficulty</strong>
                                </Form.Label>
                                <Col sm='9'>
                                    <ButtonGroup>
                                        {generateEasyPuzzleButton} {generateMediumPuzzleButton}{' '}
                                        {generateHardPuzzleButton} {level > 4 && generateVeryHardPuzzleButton}{' '}
                                        {level > 8 && generateImpossiblePuzzleButton}
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col sm='3'></Col>
                </Row>
                <div style={customSpacing}></div>
                <Row>
                    <Col sm='3'></Col>
                    <Col sm='6'>{isLoading ? <LoadingAnimation /> : <LeaderboardsTable records={records} />}</Col>
                    <Col sm='3'></Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        scorecard: state.puzzle.scorecard,
        email: state.auth.email,
        isAuthenticated: state.auth.token !== null,
        username: state.auth.username,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboards)
