export const convertToBinary = () => {
    return
}

export const convertToBoolean = (binaryState) => {
    const boolState = []
    for (let i = 0; i < binaryState.length; i++) {
        if (Number(binaryState.charAt(i)) === 1) {
            boolState.push(true)
        } else {
            boolState.push(false)
        }
    }
    return boolState
}
