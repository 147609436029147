import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { PageHeaderComponent } from '../../buildingBlocks/PageLayout/PageContent/PageHeaderComponent'
import { connect } from 'react-redux'
import { socket } from '../../socket/socket'
import { getInitialPuzzleState } from '../Puzzle/ReusablePuzzle/utils/helpers'
import { Paragraph } from '../../buildingBlocks/Text/Paragraph'
import * as actions from '../../store/actions/index'
import { Waiting } from '../../buildingBlocks/Waiting/Waiting'
import { Countdown } from '../../buildingBlocks/Countdown/Countdown'
import { Center } from '../../buildingBlocks/PageLayout/Centering/Center'
import { PuzzlePicker } from '../../buildingBlocks/ReusablePuzzlePicker/PuzzlePicker'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import { OnlineGameReusablePuzzleContainer } from '../Puzzle/ReusablePuzzle/OnlineGameReusablePuzzleContainer'
import Blur from 'react-css-blur'

export const OnlineGamePuzzleStateContext = React.createContext()
export const OnlineGameSendMoveContext = React.createContext()

const OnlineGame = ({ username, enqueueNotification, ownProps }) => {
    const dispatch = useDispatch()
    let { id: roomNumber } = useParams()

    /** Puzzles */
    const [difficulty, setDifficulty] = useState('easy')
    const [size, setSize] = useState(3)
    const [puzzleState1, setPuzzleState1] = useState(getInitialPuzzleState(size, difficulty))
    const [puzzleState2, setPuzzleState2] = useState(getInitialPuzzleState(size, difficulty))

    /**Tracking game status */
    const [gamePhase, setGamePhase] = useState('waitingForOpponent')

    /**Toggle blur on opponents board */
    const [isBlurred, setIsBlurred] = useState(false)

    /**Sending post-move puzzleState to opponent */
    const sendMove = (newPuzzle) => {
        socket.emit('updated board', { roomName: roomNumber, updatedPuzzleState: newPuzzle, username })
    }

    /**Updating game phase for everyone */
    const updateGamePhaseForEveryone = (newGamePhase) => {
        setGamePhase(newGamePhase)
        socket.emit('update game phase', { roomNumber, newGamePhase })
    }

    /**Registering handler for when opponent joins */
    React.useEffect(() => {
        socket.off('successfully joined')
        socket.on('successfully joined', ({ updatedRoom, username: joinerUsername, socketId }) => {
            if (updatedRoom.players.length === 2) {
                if (socketId !== socket.id) {
                    dispatch(
                        actions.enqueueSnackbar({
                            message: `${joinerUsername} joined the game`,
                            options: { variant: 'success' },
                        })
                    )
                    setGamePhase('selectingLevel')
                } else {
                    setGamePhase('hostPickingLevel')
                }
            }
        })
    }, [dispatch, username])

    /**Register handler for when the opponent leaves */
    React.useEffect(() => {
        socket.off('left room')
        socket.on('left room', () => {
            enqueueNotification({
                message: 'Opponent left the game',
                options: { variant: 'info' },
            })
            setGamePhase('waitingForOpponent')
        })
    }, [enqueueNotification])

    /**Join Room */
    React.useEffect(() => {
        socket.emit('join room', { roomNumber, username })
    }, [roomNumber, username])

    /**Receive opponents updated board after a move was made */
    React.useEffect(() => {
        socket.off('opponents new board')
        socket.on('opponents new board', (newState) => {
            if (!isBlurred) {
                setIsBlurred(true)
            }
            setPuzzleState2(newState)
        })
    }, [])

    /**Leave room when navigating away from the current page */
    React.useEffect(() => {
        return function cleanup() {
            socket.emit('leave room')
        }
    }, [])

    /**Receive opponents updated board after a move was made */
    React.useEffect(() => {
        socket.off('update game phase')
        socket.on('update game phase', (gamePhase) => {
            setGamePhase(gamePhase)
        })
    }, [])

    /**Receive starting puzzle */
    React.useEffect(() => {
        socket.off('starting game board')
        socket.on('starting game board', (puzzleState) => {
            setIsBlurred(false)
            setPuzzleState1(puzzleState)
            setPuzzleState2(puzzleState)
        })
    }, [])

    const broadcastStartingGameBoard = () => {
        socket.emit('starting game board', { roomNumber, startingState: getInitialPuzzleState(size, difficulty) })
    }

    /**Receive notification that someone has won */
    React.useEffect(() => {
        socket.off('winner detected')
        socket.on('winner detected', (winnerUsername) => {
            setTimeout(() => {
                let notificationConfig
                if (username === winnerUsername) {
                    notificationConfig = {
                        title: 'You win!',
                        text: 'The other player gets to pick the next puzzle.',
                        icon: 'success',
                    }
                } else {
                    notificationConfig = {
                        title: `${winnerUsername} has won.`,
                        text: 'You pick the next puzzle.',
                    }
                }
                swal(notificationConfig).then(() => {
                    if (winnerUsername !== username) {
                        setGamePhase('selectingLevel')
                    } else {
                        setGamePhase('hostPickingLevel')
                    }
                })
            }, 500)
        })
    }, [username])

    const handlePuzzleSelection = () => {
        broadcastStartingGameBoard()
        updateGamePhaseForEveryone('countDown')
    }

    if (!roomNumber) return null

    let body = null
    switch (gamePhase) {
        case 'waitingForOpponent':
            body = <Waiting messageDisplayed={'Waiting for opponent to join ...'} />
            break
        case 'selectingLevel':
            body = (
                <Center horizontalOverride={'35%'}>
                    <Paragraph block fontSize={'2rem'} paddingLeft>
                        Select puzzle
                    </Paragraph>
                    <div style={{ marginBottom: '4%' }}></div>
                    <PuzzlePicker size={size} setSize={setSize} difficulty={difficulty} setDifficulty={setDifficulty} />
                    <div style={{ marginBottom: '0%' }}></div>
                    <div style={{ marginLeft: '15px' }}>
                        <Button variant='success' onClick={handlePuzzleSelection}>
                            Start
                        </Button>
                    </div>
                </Center>
            )
            break
        case 'hostPickingLevel':
            body = <Waiting messageDisplayed={'The other player is picking the puzzle ...'} />
            break
        case 'countDown':
            body = <Countdown seconds={5} setGamePhase={setGamePhase} />
            break
        case 'gameInProgress':
            body = (
                <React.Fragment>
                    <Col md='6'>
                        <OnlineGameReusablePuzzleContainer player={'you'} key='you' puzzleIndex={0} />
                    </Col>
                    <Blur radius={isBlurred ? '15px' : '0'} transition='500ms'>
                        <Col md='6'>
                            <OnlineGameReusablePuzzleContainer player={'opponent'} key='opponent' puzzleIndex={1} />
                        </Col>
                    </Blur>
                </React.Fragment>
            )
            break
        default:
            body = null
    }

    return (
        <>
            <PageHeaderComponent
                component={
                    <Paragraph fontSize='2rem' marginBottom marginTop>
                        Multiplayer
                    </Paragraph>
                }
            />
            <Container>
                <OnlineGamePuzzleStateContext.Provider
                    value={[
                        [puzzleState1, setPuzzleState1],
                        [puzzleState2, setPuzzleState2],
                    ]}>
                    <Row>
                        <OnlineGameSendMoveContext.Provider value={sendMove}>{body}</OnlineGameSendMoveContext.Provider>
                    </Row>
                </OnlineGamePuzzleStateContext.Provider>
            </Container>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        props: ownProps,
        username: state.auth.username,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enqueueNotification: (message) => dispatch(actions.enqueueSnackbar(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineGame)
