import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { ThemeContext } from '../../App'
import { useHistory } from 'react-router-dom'
import { api } from '../../axios/axios'

export const PrivateGame = () => {
    const theme = useContext(ThemeContext)
    const history = useHistory()

    const handleCreateGame = async () => {
        const { data } = await api.get('/getUniqueRoomId')
        if (data) {
            history.push(`/multiplayer/game/${data.roomNumber}`)
        }
    }

    return (
        <>
            <Row>
                <Col sm='3'></Col>
                <Col sm='6'>
                    <h1 className={'navlinks_' + theme.theme}>private game</h1>
                </Col>
                <Col sm='3'></Col>
            </Row>
            <Row>
                <Col sm='3'></Col>
                <Col sm='6'>
                    <Button style={{ marginRight: '10px' }} onClick={handleCreateGame}>
                        Create Game
                    </Button>
                    <Button onClick={() => history.push('/multiplayer/joinGame')}>Join Game</Button>
                </Col>
                <Col sm='3'></Col>
            </Row>
        </>
    )
}
