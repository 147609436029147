import React from 'react'
import { Row, Col } from 'react-bootstrap'

export const PageHeaderComponent = ({ component }) => {
    const style = {
        marginTop: '20px',
        marginBottom: '20px',
    }

    return (
        <Row style={style}>
            <Col md={2}></Col>
            <Col md={8}>{component}</Col>
            <Col md={2}></Col>
        </Row>
    )
}
