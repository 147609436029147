import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function SolveStatusModal(props) {
    const getRandomInt = (max) => {
        return Math.floor(Math.random() * Math.floor(max))
    }

    const getClover = () => {
        const roll = getRandomInt(4)
        if (roll === 2) {
            return '🍀'
        } else {
            return '☘️'
        }
    }

    return (
        <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>Success!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Puzzle solved! Wanna try a harder one? </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Sure {getClover()}</Button>
            </Modal.Footer>
        </Modal>
    )
}
