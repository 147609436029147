import { Firestore } from './firestore'
import { getNewScorecard } from '../store/utility'

const db = Firestore.getInstance()

export const firestoreUpdateCompletedPuzzle = async (email, size, difficulty) => {
    const userRef = db.collection('users')
    try {
        let userSnapshot = await userRef.where('email', '==', email).get()
        let targetDocId
        let scorecard
        userSnapshot.forEach((doc) => {
            targetDocId = doc.id
            scorecard = doc.data().scorecard
        })
        scorecard[size][difficulty] = true

        userRef.doc(targetDocId).update({
            scorecard: scorecard,
        })
    } catch (err) {
        console.error(err)
    }
}

export const addNewUser = (email, username) => {
    const newScorecard = getNewScorecard()

    db.collection('users').add({
        email,
        username,
        scorecard: newScorecard,
        showOnboarding: true,
    })
}

export const getUserData = async (email) => {
    const usersRef = db.collection('users')
    let scorecard
    let showOnboarding
    let username
    try {
        let userDataSnapshot = await usersRef.where('email', '==', email).get()
        userDataSnapshot.forEach((doc) => {
            scorecard = doc.data().scorecard
            showOnboarding = doc.data().showOnboarding
            username = doc.data().username
        })
        return { scorecard, showOnboarding, username }
    } catch (err) {
        console.error(err)
    }
}

export const updateUserShowOnboarding = async (email, onboarding) => {
    const userRef = db.collection('users')
    try {
        let userSnapshot = await userRef.where('email', '==', email).get()
        let targetDocId
        userSnapshot.forEach((doc) => {
            targetDocId = doc.id
        })

        userRef.doc(targetDocId).update({
            showOnboarding: onboarding,
        })
    } catch (err) {
        console.error(err)
    }
}

export const addNewScore = (size, difficulty, username, movesToSolve) => {
    const collection = `${size}_${difficulty}`
    const collectionRef = db.collection(collection)
    collectionRef.add({
        username,
        movesToSolve,
    })
}

export const setUserScore = async (size, difficulty, username, movesToSolve) => {
    const collection = `${size}_${difficulty}`
    const collectionRef = db.collection(collection)
    try {
        let userSnapshot = await collectionRef.where('username', '==', username).get()
        let targetDocId
        let previousBest

        if (userSnapshot.empty) {
            addNewScore(size, difficulty, username, movesToSolve)
        } else {
            userSnapshot.forEach((doc) => {
                targetDocId = doc.id
                previousBest = doc.data().movesToSolve
            })

            if (movesToSolve < previousBest) {
                collectionRef.doc(targetDocId).update({
                    movesToSolve: movesToSolve,
                })
            }
        }
    } catch (err) {
        console.error(err)
    }
}

export const getLeaderboard = async (size, difficulty) => {
    const collection = `${size}_${difficulty}`
    const collectionRef = db.collection(collection)
    try {
        let leaderboardSnapshot = await collectionRef.orderBy('movesToSolve').limit(50).get()
        let leaderboard = []

        leaderboardSnapshot.forEach((doc) => {
            leaderboard.push(doc.data())
        })

        return leaderboard
    } catch (err) {
        console.error(err)
    }
}

export const isUsernameTaken = async (username) => {
    const collection = 'users'
    const collectionRef = db.collection(collection)
    try {
        let userSnapshot = await collectionRef.where('username', '==', username).get()
        return userSnapshot.empty
    } catch (err) {
        console.err(err)
    }
    return true
}

export const doesEmailExist = async (email) => {
    const collection = 'users'
    const collectionRef = db.collection(collection)
    try {
        let userSnapshot = await collectionRef.where('email', '==', email).get()
        return userSnapshot.empty
    } catch (err) {
        console.error(err)
    }
}
