import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  results: [],
}

const deleteSnapshot = (state, action) => {
  const updatedResults = state.results.filter((result) => result.id !== action.deleteElId)
  return updateObject(state, { results: updatedResults })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SNAPSHOT:
      return deleteSnapshot(state, action)
    case actionTypes.STORE_STATE:
      return updateObject(state, { results: state.results.concat({ id: new Date(), value: action.value }) })
    default:
      return state
  }
}

export default reducer
