import React, { useContext } from 'react'
import { ThemeContext } from '../../App'

export const Paragraph = ({ marginBottom, marginTop, block, fontSize, children, paddingLeft }) => {
    const theme = useContext(ThemeContext)

    const style = {}

    if (marginBottom) {
        style.marginBottom = '5px'
    }

    if (marginTop) {
        style.marginTop = '5px'
    }

    if (block) {
        style.display = 'block'
    }

    if (fontSize) {
        style.fontSize = fontSize
    }

    if (paddingLeft) {
        style.paddingLeft = '15px'
    }

    return (
        <strong>
            <span style={style} className={'navlinks_' + theme.theme}>
                {children}
            </span>
        </strong>
    )
}
