import React from 'react'
import { useDispatch } from 'react-redux'
import { Clipboard } from 'react-bootstrap-icons'
import * as actions from '../../store/actions/index'
import styled from 'styled-components'
import { lighten } from 'polished'

export const CopyToClipboard = ({ children }) => {
    const dispatch = useDispatch()

    const handleCopyToClipboard = (e) => {
        navigator.clipboard.writeText(e.target.innerText)
        dispatch(
            actions.enqueueSnackbar({
                message: `room name copied to clipboard`,
                options: { variant: 'success' },
            })
        )
    }

    return (
        <RoomNumberContainer onClick={handleCopyToClipboard}>
            {children} <Clipboard size={15} />
        </RoomNumberContainer>
    )
}

const RoomNumberContainer = styled.span`
    color: #009b72;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        color: ${lighten(0.4, '#009b72')};
    }
`
