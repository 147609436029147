import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from '../../App'
import { Waiting } from '../../buildingBlocks/Waiting/Waiting'
import { socket } from '../../socket/socket'

const PublicGame = ({ username }) => {
    const theme = useContext(ThemeContext)
    const history = useHistory()
    const [status, setStatus] = React.useState('idle')

    const handleInitiateMatchmaking = () => {
        setStatus('searchingForGame')
        socket.emit('join matchmaking queue', { username })
    }

    React.useEffect(() => {
        socket.off('public game joined')
        socket.on('public game joined', (roomName) => {
            history.push(`/multiplayer/onlineGame/${roomName}`)
        })
    }, [history])

    let body = null
    switch (status) {
        case 'idle':
            body = (
                <>
                    <Row>
                        <Col sm='3'></Col>
                        <Col sm='6'>
                            <h1 className={'navlinks_' + theme.theme}>public game</h1>
                        </Col>
                        <Col sm='3'></Col>
                    </Row>
                    <Row>
                        <Col sm='3'></Col>
                        <Col sm='6'>
                            <Button onClick={handleInitiateMatchmaking}>Find a Game</Button>
                        </Col>
                        <Col sm='3'></Col>
                    </Row>
                </>
            )
            break
        case 'searchingForGame':
            body = <Waiting messageDisplayed={'Searching for a game ...'} />
            break
        default:
            body = null
    }

    return <>{body}</>
}

const mapStateToProps = (state) => {
    return {
        username: state.auth.username,
    }
}

export default connect(mapStateToProps)(PublicGame)
