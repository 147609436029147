import React, { useContext } from 'react'
import { Table } from 'react-bootstrap'
import { range } from '../../utils/iteration/python'
import { ThemeContext } from '../../App'

const LeaderboardsTable = (props) => {
    const theme = useContext(ThemeContext)

    const getTableRow = (i, data) => {
        return (
            <tr className={'table-body_' + theme.theme} key={i}>
                <td>{i + 1}</td>
                <td>{data['username']}</td>
                <td>{data['movesToSolve']}</td>
            </tr>
        )
    }

    let tableRows = range(props.records.length)
        .map((el) => {
            return getTableRow(el, props.records[el])
        })
        .reduce((accumulator, el) => accumulator.concat(el), [])

    if (tableRows.length === 0) {
        tableRows = (
            <tr className={'table-body_' + theme.theme}>
                <td colSpan='3'>
                    No one has completed this puzzle yet{' '}
                    <span role='img' aria-label='mindblow emoji'>
                        🤯
                    </span>
                </td>
            </tr>
        )
    }

    const table = (
        <Table striped bordered hover>
            <thead>
                <tr className={'alert-warning_' + theme.theme}>
                    <th>#</th>
                    <th>username</th>
                    <th>moves to solve</th>
                </tr>
            </thead>
            <tbody className={'table-body_' + theme.theme}>{tableRows}</tbody>
        </Table>
    )

    return table
}

export default LeaderboardsTable
