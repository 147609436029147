import React from 'react'
import { GuardSpinner } from 'react-spinners-kit'

const LoadingAnimation = () => {
    const spinnerStyle = {
        position: 'absolute',
        left: '50%',
        top: '70px',
        transform: 'translate(-50%, -50%)',
    }

    return (
        <div style={spinnerStyle}>
            <GuardSpinner frontColor='#e14594' backColor='#2b3595' size={50} />
        </div>
    )
}

export default LoadingAnimation
