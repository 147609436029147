import React from 'react'
import { Center } from '../../buildingBlocks/PageLayout/Centering/Center'
import LoadingAnimation from '../../buildingBlocks/Loading/LoadingAnimation'
import { Paragraph } from '../../buildingBlocks/Text/Paragraph'

export const Waiting = ({ messageDisplayed }) => {
    return (
        <Center horizontalOverride={'42%'} verticalOverride={'45%'}>
            <LoadingAnimation />
            <Paragraph>{messageDisplayed}</Paragraph>
        </Center>
    )
}
