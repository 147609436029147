import * as actionTypes from './actionTypes'
import * as notistack from './notistack'
import axios from 'axios'
import { addNewUser, getUserData } from '../../firebase/tdg'
import { initializeScorecard, updateScorecard, updateLocalShowOnboarding } from './index'
import { getNextPuzzleInLine } from '../../store/utility'
import { setPuzzleSize, setPuzzleDifficulty } from './index'

const API_KEY = 'AIzaSyCsKQ7yGsAHGgucTb4NDPFJYBdt4xSjqWo'

const getErrorMessage = (error) => {
    let errorMessage
    switch (error) {
        case 'EMAIL_NOT_FOUND':
            errorMessage = 'Email not found. Please sign up! '
            break
        case 'INVALID_PASSWORD':
            errorMessage = 'Invalid Email or Password'
            break
        case 'USER_DISABLED':
            errorMessage = '🚨 User has been disabled. Please contact support.'
            break
        default:
            errorMessage = '🤔 Something went wrong, please try again.'
    }
    return errorMessage
}

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    }
}

export const updateUsername = (username) => {
    return {
        type: actionTypes.UPDATE_USERNAME,
        username: username,
    }
}

export const updateWasRedirected = (wasRedirected) => {
    return {
        type: actionTypes.UPDATE_WAS_REDIRECTED,
        wasRedirected: wasRedirected,
    }
}

export const authSuccess = (idToken, userId, email) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: idToken,
        userId: userId,
        email: email,
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    }
}

export const logoutHandler = () => {
    return (dispatch) => {
        dispatch(
            notistack.enqueueSnackbar({
                message: 'Successfully Logged Out!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    autoHideDuration: 2500,
                },
            })
        )
        dispatch(logout())
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch(logoutHelper())
        dispatch(initializeScorecard())
        dispatch(setPuzzleSize(3))
        dispatch(setPuzzleDifficulty('easy'))
    }
}

const logoutHelper = () => {
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('email')
    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}

export const checkAuthTimeout = (expiresIn) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(logout())
        }, expiresIn * 1000)
    }
}

export const auth = (email, password, isLogin, username) => {
    return (dispatch) => {
        dispatch(authStart())

        const userData = {
            email: email,
            password: password,
            returnSecureToken: true,
        }

        const url = isLogin
            ? 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCsKQ7yGsAHGgucTb4NDPFJYBdt4xSjqWo'
            : 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCsKQ7yGsAHGgucTb4NDPFJYBdt4xSjqWo'

        axios
            .post(url, userData)
            .then((response) => {
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
                localStorage.setItem('token', response.data.idToken)
                localStorage.setItem('expirationDate', expirationDate)
                localStorage.setItem('userId', response.data.localId)
                localStorage.setItem('email', userData.email)
                dispatch(authSuccess(response.data.idToken, response.data.localId, userData.email))
                isLogin ? handleLogin(dispatch, userData.email) : handleRegistration(dispatch, userData.email, username)

                dispatch(checkAuthTimeout(response.data.expiresIn))
            })
            .catch((err) => {
                try {
                    const errorMessage = getErrorMessage(err.response.data.error.message)
                    dispatch(
                        notistack.enqueueSnackbar({
                            message: errorMessage,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2500,
                            },
                        })
                    )
                    dispatch(authFail(err.response.data.error.message))
                } catch (err) {
                    console.error(err)
                }
            })
    }
}

const handleLogin = async (dispatch, email) => {
    const { scorecard, showOnboarding, username } = await getUserData(email)
    dispatch(updateUsername(username))
    dispatch(updateScorecard(scorecard))
    const { size, difficulty } = getNextPuzzleInLine(scorecard)
    dispatch(setPuzzleSize(size))
    dispatch(updateLocalShowOnboarding(showOnboarding))
    dispatch(
        notistack.enqueueSnackbar({
            message: 'Welcome Back!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                autoHideDuration: 2500,
            },
        })
    )
    dispatch(setPuzzleDifficulty(difficulty))
}

const handleRegistration = (dispatch, email, username) => {
    addNewUser(email, username)
    dispatch(initializeScorecard())
    dispatch(updateLocalShowOnboarding(true))
    dispatch(updateUsername(username))
    dispatch(
        notistack.enqueueSnackbar({
            message: 'Welcome To Square Solve!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                autoHideDuration: 2500,
            },
        })
    )
}

export const authCheckState = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token')
        const email = localStorage.getItem('email')
        if (!token || !email) {
            dispatch(logout())
        } else {
            const expirationTime = new Date(localStorage.getItem('expirationDate'))
            if (expirationTime <= new Date()) {
                dispatch(logout())
            } else {
                const userId = localStorage.getItem('userId')
                dispatch(authSuccess(token, userId, email))
                const timeToExpirationInMilliseconds = (expirationTime.getTime() - new Date().getTime()) / 1000
                handleLogin(dispatch, email)
                dispatch(checkAuthTimeout(timeToExpirationInMilliseconds))
            }
        }
    }
}

export const resetPassword = (email) => {
    return (dispatch) => {
        const requestPayload = {
            requestType: 'PASSWORD_RESET',
            email: email,
        }

        const url = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${API_KEY}`

        axios
            .post(url, requestPayload)
            .then((response) => {
                const userEmailConfirmation = response.data.email
                dispatch(resetPasswordConfirmation(userEmailConfirmation))
                dispatch(
                    notistack.enqueueSnackbar({
                        message: 'Password reset email sent.',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                            autoHideDuration: 2500,
                        },
                    })
                )
            })
            .catch((err) => {
                console.error(err)
            })
    }
}

export const resetPasswordConfirmation = (email) => {
    return {
        type: actionTypes.RESET_PASSWORD_CONFIRMATION_EMAIL,
        email: email,
    }
}
