import firebase from 'firebase'

export var Firestore = (function () {
    var db = null

    function createInstance() {
        const firebaseConfig = {
            apiKey: 'AIzaSyCsKQ7yGsAHGgucTb4NDPFJYBdt4xSjqWo',
            authDomain: 'mediato-81951.firebaseapp.com',
            databaseURL: 'https://mediato-81951.firebaseio.com',
            projectId: 'mediato-81951',
            storageBucket: 'mediato-81951.appspot.com',
            messagingSenderId: '64661370432',
            appId: '1:64661370432:web:42b19d7b8a8c3f4c36d1a5',
            measurementId: 'G-C7LRKZ1P6F',
        }

        firebase.initializeApp(firebaseConfig)
        return firebase.firestore()
    }

    return {
        getInstance() {
            if (!db) {
                db = createInstance()
            }
            return db
        },
    }
})()
