import React, { useState, useContext } from 'react'
import { Row, Col, Form, Button, Spinner, Alert, InputGroup, FormControl } from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from 'axios'
import Board from './Board'
import * as actions from '../../../store/actions/index'
import SolveStatusModal from '../../Notifications/SolveStatusModal'
import SolveSuccessModal from '../../Notifications/SolveSuccessModal'
import ConfirmationPrompt from '../../Notifications/ConfirmationPrompt'
import { convertToBoolean } from '../../../utils/state/stateConversion'
import SizeSelector from '../Components/SizeSelector'
import swal from 'sweetalert'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import { getInitialPuzzleState, getNextDifficulty } from '../../../store/utility'
import { ThemeContext } from '../../../App'
import { PuzzleDifficultySelector } from '../Components/PuzzleDifficulty/PuzzleDifficultySelector'

const ENABLE_SOLVING = false
const ENABLE_RESET = false

const convertPuzzleState = (state) => {
    const convertedState = state.map((el) => {
        if (!el) {
            return String(0)
        } else {
            return String(1)
        }
    })
    return convertedState.join('')
}

const Puzzle = ({
    puzzleSize,
    puzzleState,
    puzzleDifficulty,
    wasTouched,
    scorecard,
    email,
    username,
    isAuthenticated,
    setPuzzleSize,
    setPuzzleState,
    setPuzzleDifficulty,
    setWasTouched,
    setCompletedPuzzle,
}) => {
    const [disabled, setDisabled] = useState(false)
    const [solveStatus, setSolveStatus] = useState({})
    const [solveStatusShow, setSolveStatusShow] = useState(false)
    const [solveSuccessShow, setSolveSuccessShow] = useState(false)
    const [confirmationPrompShow, setConfirmationPromptShow] = useState(false)
    const [showLoginEncouragement, setShowLoginEncouragement] = useState(true)
    const [showConfetti, setShowConfetti] = useState(false)
    const [movesMade, setMovesMade] = useState(0)
    const theme = useContext(ThemeContext)

    const { width, height } = useWindowSize()

    let desiredDifficulty = 'easy'
    let desiredSize = 3

    const getSuccessMessage = () => {
        let message
        const { nextSize, nextDifficulty } = getNextPuzzle()
        const isGameOver = nextSize === 3 && nextDifficulty === 'easy' ? true : false

        const movesToCompleteMessage = `Solved in ${movesMade} move${movesMade !== 1 ? 's' : ''}! `

        if (!isGameOver) {
            if (nextSize !== puzzleSize) {
                message = `Level ${puzzleSize - 2} complete! Level ${nextSize - 2} up next.`
                setShowConfetti(true)
                setTimeout(() => {
                    setShowConfetti(false)
                }, 5000)
            } else {
                const difficulties = {
                    easy: 'easy',
                    med: 'medium',
                    hard: 'hard',
                    vhard: 'very hard',
                    xhard: 'suuuuper hard',
                }
                message = `Next up is a ${difficulties[nextDifficulty]} puzzle.`
            }
        } else {
            message = "Nice. You've completed all the puzzles. I guess time to find a new game or something"
        }
        return { successMessage: message, isGameOver: isGameOver, successMessageHeader: movesToCompleteMessage }
    }

    if (wasTouched && !puzzleState.includes(true)) {
        setCompletedPuzzle(puzzleSize, puzzleDifficulty, email, username, movesMade, isAuthenticated)
        setTimeout(() => {
            setWasTouched(false)
            const { successMessage, isGameOver, successMessageHeader } = getSuccessMessage()
            swal(successMessageHeader, successMessage, 'success').then((val) => {
                setMovesMade(0)
                generateNextPuzzle()
            })
            if (isGameOver) {
                setShowConfetti(true)
            }
        }, 600)
    }

    const customSpacing = {
        marginBottom: '20px',
    }

    const customSpacingSmall = {
        marginBottom: '0px',
    }

    const customSpacingLarge = {
        marginBottom: '4%',
    }

    const handlePuzzleSolve = () => {
        setActionButton(loadingButton)
        setDisabled(true)

        const reformattedPuzzleState = convertPuzzleState(puzzleState)
        axios
            .post('/solve_simple', {
                state: reformattedPuzzleState,
                searchOptions: {
                    max_d: '200',
                    max_p: '500',
                },
                strategies: {
                    dfs: true,
                    bfs: true,
                    a_star: true,
                },
            })
            .then((response) => {
                setSolveStatus(response.data.statuses)
            })
            .catch((err) => {
                console.error(err)
                setSolveStatus(err)
            })
            .finally(() => {
                setActionButton(solveButton)
                setSolveStatusShow(true)
                setDisabled(false)
            })
    }

    const handleConfirmSizeChange = (newSize) => {
        if (wasTouched) {
            desiredSize = newSize
            desiredDifficulty = puzzleDifficulty
            setProceedButton(proceedChangePuzzleSize)
            setConfirmationPromptShow(true)
        } else {
            setPuzzleSizeWrapper(newSize)
        }
    }

    const handleConfirmResetPuzzle = () => {
        if (wasTouched) {
            desiredSize = puzzleSize
            desiredDifficulty = puzzleDifficulty
            setProceedButton(proceedGeneratePuzzle)
            setConfirmationPromptShow(true)
        } else {
            handlePuzzleReset()
        }
    }

    const handlePuzzleReset = () => {
        handleGeneratePuzzle(puzzleSize, puzzleDifficulty)
    }

    const handleConfirmGeneratePuzzle = (puzzle_size, difficulty) => {
        if (wasTouched) {
            desiredSize = puzzle_size
            desiredDifficulty = difficulty
            setProceedButton(proceedGeneratePuzzle)
            setConfirmationPromptShow(true)
        } else {
            handleGeneratePuzzle(puzzle_size, difficulty)
        }
    }

    const handleGeneratePuzzle = (puzzle_size, difficulty) => {
        setMovesMade(0)
        // for cases where we got here from the confirmation prompt
        if (confirmationPrompShow) {
            setConfirmationPromptShow(false)
        }

        // for cases where the puzzle has been solved and the user is selecting a new difficulty
        if (solveSuccessShow) {
            setSolveSuccessShow(false)
        }

        setPuzzleDifficulty(difficulty)

        const booleanState = convertToBoolean(getInitialPuzzleState(puzzle_size, difficulty))
        setPuzzleState(booleanState)
    }

    const getNextPuzzle = () => {
        let nextSize, nextDifficulty
        const p = [
            ['easy', 'med', 'hard'],
            ['easy', 'med', 'hard'],
            ['easy', 'med', 'hard', 'vhard'],
            ['easy', 'med', 'hard', 'vhard'],
            ['easy', 'med', 'hard', 'vhard'],
            ['easy', 'med', 'hard', 'vhard'],
            ['easy', 'med', 'hard', 'vhard', 'xhard'],
            ['easy', 'med', 'hard', 'vhard', 'xhard'],
        ]

        const currentSize = puzzleSize - 3
        const currentDifficulty = p[currentSize].indexOf(puzzleDifficulty)
        if (currentDifficulty !== p[currentSize].length - 1) {
            nextSize = puzzleSize
            nextDifficulty = p[currentSize][currentDifficulty + 1]
        } else if (currentSize === p.length - 1) {
            nextSize = 3
            nextDifficulty = 'easy'
        } else {
            nextSize = puzzleSize + 1
            nextDifficulty = 'easy'
        }

        return { nextSize, nextDifficulty }
    }

    const generateNextPuzzle = () => {
        const { nextSize, nextDifficulty } = getNextPuzzle()

        setPuzzleSize(nextSize)
        handleGeneratePuzzle(nextSize, nextDifficulty)
    }

    const setPuzzleSizeWrapper = (size) => {
        const nextDifficulty = getNextDifficulty(scorecard, size)

        setPuzzleSize(size)
        setTimeout(() => {
            setPuzzleDifficulty(nextDifficulty)
            handleGeneratePuzzle(size, nextDifficulty)
        }, 0)
    }

    const proceedGeneratePuzzle = (
        <Button
            onClick={() => {
                handleGeneratePuzzle(desiredSize, desiredDifficulty)
                setConfirmationPromptShow(false)
            }}>
            Yup I know, continue
        </Button>
    )

    const proceedChangePuzzleSize = (
        <Button
            onClick={() => {
                setPuzzleSizeWrapper(desiredSize)
                setConfirmationPromptShow(false)
            }}
            variant='warning'>
            Yup I know, continue
        </Button>
    )

    const [proceedButton, setProceedButton] = useState(proceedGeneratePuzzle)

    const solveButton = 'Solve'

    const loadingButton = (
        <React.Fragment>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> <span></span>
            Loading...
        </React.Fragment>
    )

    const [actionButton, setActionButton] = useState(solveButton)

    const incrementMovesMade = () => {
        setMovesMade(movesMade + 1)
    }

    return (
        <React.Fragment>
            <div>
                <div style={customSpacing}></div>
                <Row>
                    <Col sm='1'></Col>
                    <Col sm='10'>
                        <Row>
                            <Col sm='2'></Col>
                            <Col sm='8'>
                                <Alert
                                    className={'alert-warning_' + theme.theme}
                                    show={!isAuthenticated && showLoginEncouragement}
                                    variant='warning'
                                    onClose={() => setShowLoginEncouragement(false)}
                                    dismissible>
                                    <Alert.Heading>
                                        <span role='img' aria-label='person silhouette emoji'>
                                            👤
                                        </span>{' '}
                                        login to save your progress! ↗️
                                    </Alert.Heading>
                                    <p>
                                        Logging in will let you save your puzzle solving progress, so you can always
                                        pick up from where you left off, on any device!
                                    </p>
                                </Alert>
                            </Col>
                            <Col sm='2'></Col>
                        </Row>
                        <div style={customSpacing}></div>
                        <Row>
                            <Col sm='2'></Col>
                            <Col sm='8'>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm='3'>
                                            <strong>
                                                <span className={'navlinks_' + theme.theme}>level</span>
                                            </strong>
                                        </Form.Label>
                                        <Col sm='9'>
                                            <SizeSelector
                                                selectedSize={puzzleSize}
                                                scorecard={scorecard}
                                                handleClick={handleConfirmSizeChange}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col sm='2'></Col>
                        </Row>
                        <div style={customSpacingSmall}></div>
                        <Row>
                            <Col sm='2'></Col>
                            <Col sm='8'>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm='3'>
                                            <strong>
                                                <span className={'navlinks_' + theme.theme}>difficulty</span>
                                            </strong>
                                        </Form.Label>
                                        <Col sm='9'>
                                            <PuzzleDifficultySelector
                                                puzzleSize={puzzleSize}
                                                handleConfirmGeneratePuzzle={handleConfirmGeneratePuzzle}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col sm='2'></Col>
                        </Row>
                        <div style={customSpacingSmall}></div>
                        <Row>
                            <Col sm='2'></Col>
                            <Col sm='8'>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm='3'>
                                            <strong>
                                                <span className={'navlinks_' + theme.theme}>moves made</span>
                                            </strong>
                                        </Form.Label>
                                        <Col sm='3'>
                                            <InputGroup>
                                                <FormControl readOnly disabled value={movesMade}></FormControl>
                                                <InputGroup.Append>
                                                    <Button variant='primary' onClick={handleConfirmResetPuzzle}>
                                                        Reset
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                        <Col sm='6'></Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col sm='2'></Col>
                        </Row>
                        <div style={customSpacing}></div>
                        <Row>
                            <Col sm='2'></Col>
                            <Col sm='8'>
                                <Board
                                    incrementMovesMade={incrementMovesMade}
                                    puzzleSize={puzzleSize}
                                    handleGeneratePuzzle={handleGeneratePuzzle}
                                />
                            </Col>
                            <Col sm='2'></Col>
                        </Row>
                        <div style={customSpacing}></div>
                        <Row>
                            {ENABLE_RESET && (
                                <Col sm='6'>
                                    <div className='col text-center'>
                                        <Button variant='primary' onClick={handleConfirmResetPuzzle}>
                                            Reset
                                        </Button>
                                    </div>
                                </Col>
                            )}
                            {ENABLE_SOLVING && (
                                <Col sm='6'>
                                    <div className='col text-center'>
                                        <Button variant='primary' disabled={disabled} onClick={handlePuzzleSolve}>
                                            {actionButton}
                                        </Button>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col sm='1'></Col>
                </Row>
                <div style={customSpacingLarge}></div>
            </div>
            <SolveStatusModal
                show={solveStatusShow}
                solveStatus={solveStatus}
                onHide={() => setSolveStatusShow(false)}
            />
            <SolveSuccessModal show={solveSuccessShow} onHide={generateNextPuzzle} />
            <ConfirmationPrompt
                show={confirmationPrompShow}
                proceedButton={proceedButton}
                onHide={() => setConfirmationPromptShow(false)}
            />
            {showConfetti && <Confetti width={width} height={height} tweenDuration={5000} />}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        puzzleSize: state.puzzle.puzzleSize,
        puzzleState: state.puzzle.puzzleState,
        wasTouched: state.puzzle.wasTouched,
        puzzleDifficulty: state.puzzle.difficulty,
        scorecard: state.puzzle.scorecard,
        email: state.auth.email,
        showOnboarding: state.puzzle.showOnboarding,
        isAuthenticated: state.auth.token !== null,
        username: state.auth.username,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPuzzleSize: (size) => dispatch(actions.changePuzzleSize(size)),
        setPuzzleState: (state) => dispatch(actions.setPuzzleState(state)),
        setPuzzleDifficulty: (difficulty) => dispatch(actions.setPuzzleDifficulty(difficulty)),
        setWasTouched: (wasTouched) => dispatch(actions.setWasTouched(wasTouched)),
        setCompletedPuzzle: (email, size, difficulty, username, movesToSolve, isAuthenticated) =>
            dispatch(actions.updateCompletedPuzzle(email, size, difficulty, username, movesToSolve, isAuthenticated)),
        setShowOnboarding: (email, showOnboarding) => dispatch(actions.updateShowOnboarding(email, showOnboarding)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Puzzle)
