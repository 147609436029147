import React, { useEffect } from 'react'
import Piece from '../Components/Piece'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { range } from '../../../utils/iteration/python'
import { getNextDifficulty } from '../../../store/utility'

const Board = ({ props, puzzleSize, scorecard, resetPuzzle, setPuzzleState }) => {
    useEffect(() => {
        const nextDifficulty = getNextDifficulty(scorecard, puzzleSize)
        props.handleGeneratePuzzle(puzzleSize, nextDifficulty)
        // eslint-disable-next-line
    }, [puzzleSize, scorecard])

    const tableStyle = {
        boarder: '2px solid black',
        width: '100%',
    }

    const tdStyle = {
        position: 'relative',
        width: `${100 / props.puzzleSize}%`,
        height: `${100 / props.puzzleSize}%`,
        paddingTop: `${100 / props.puzzleSize}%`,
    }

    const tableData = (i) => {
        return (
            <td style={tdStyle} key={i}>
                <Piece incrementMovesMade={props.incrementMovesMade} index={i} />
            </td>
        )
    }

    const tableRow = (i) => {
        const tableColumns = range(props.puzzleSize)
            .map((el) => tableData(el + i * props.puzzleSize))
            .reduce((accumulator, el) => accumulator.concat(el), [])

        return <tr key={i}>{tableColumns}</tr>
    }

    const tableRows = range(props.puzzleSize)
        .map((i) => tableRow(i))
        .reduce((accumulator, el) => accumulator.concat(el), [])

    const table = (
        <table style={tableStyle}>
            <tbody>{tableRows}</tbody>
        </table>
    )

    return table
}

const mapStateToProps = (state, ownProps) => {
    return {
        props: ownProps,
        puzzleSize: state.puzzle.puzzleSize,
        scorecard: state.puzzle.scorecard,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPuzzleState: (state) => dispatch(actions.setPuzzleState(state)),
        resetPuzzle: (size) => dispatch(actions.resetPuzzle(size)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Board)
