import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'

const PuzzleDifficultyButton = ({ puzzleSize, puzzleDifficulty, scorecard, props }) => {
    const { handleConfirmGeneratePuzzle, targetPuzzleSize, buttonLabel } = props

    return (
        <Button
            onClick={() => handleConfirmGeneratePuzzle(puzzleSize, targetPuzzleSize)}
            active={puzzleDifficulty === targetPuzzleSize}
            variant={scorecard[puzzleSize][targetPuzzleSize] ? 'success' : 'primary'}>
            {scorecard[puzzleSize][targetPuzzleSize] ? '✔️' : buttonLabel}
        </Button>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        puzzleSize: state.puzzle.puzzleSize,
        puzzleDifficulty: state.puzzle.difficulty,
        scorecard: state.puzzle.scorecard,
        props: ownProps,
    }
}

export default connect(mapStateToProps)(PuzzleDifficultyButton)
