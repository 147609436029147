import * as actionTypes from '../actions/actionTypes'
import { updateObject, flipPiece, setCompleted, getNewScorecard } from '../utility'

const initialState = {
    puzzleState: [],
    puzzleSize: 3,
    wasTouched: false,
    difficulty: 'easy',
    showOnboarding: true,
    scorecard: {
        3: {
            easy: false,
            med: false,
            hard: false,
        },
        4: {
            easy: false,
            med: false,
            hard: false,
        },
        5: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        6: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        7: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        8: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
        },
        9: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
            xhard: false,
        },
        10: {
            easy: false,
            med: false,
            hard: false,
            vhard: false,
            xhard: false,
        },
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FLIP_PIECE:
            return flipPiece(state, action)
        case actionTypes.SET_PUZZLE_STATE:
            return updateObject(state, { puzzleState: action.newState, wasTouched: false })
        case actionTypes.SET_PUZZLE_SIZE:
            return updateObject(state, { puzzleSize: action.size })
        case actionTypes.SET_PUZZLE_DIFFICULTY:
            return updateObject(state, { difficulty: action.difficulty })
        case actionTypes.SET_WAS_TOUCHED:
            return updateObject(state, { wasTouched: action.wasTouched })
        case actionTypes.SET_COMPLETED:
            return setCompleted(state, action)
        case actionTypes.INITIALIZE_SCORECARD:
            return updateObject(state, { scorecard: getNewScorecard() })
        case actionTypes.UPDATE_SCORECARD:
            return updateObject(state, { scorecard: action.scorecard })
        case actionTypes.UPDATE_SHOW_ONBOARDING:
            return updateObject(state, { showOnboarding: action.showOnboarding })
        default:
            return state
    }
}

export default reducer
