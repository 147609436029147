import React, { useContext } from 'react'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import * as yup from 'yup'
import { LinkContainer } from 'react-router-bootstrap'
import { ThemeContext } from '../../../App'

const initialValues = { email: '', password: '' }
const validationSchema = yup.object().shape({
    email: yup.string().email().required('Please enter an email'),
    password: yup
        .string()
        .min(5, 'Password must be at least 5 characters')
        .max(50, 'Password must be at most 50 characters')
        .required('Please enter a password'),
})

const Login = ({ onAuth, error }) => {
    const theme = useContext(ThemeContext)

    return (
        <div className='horizontal-center'>
            <div className='vertical-center'>
                <Formik
                    validationSchema={validationSchema}
                    onSubmit={(event) => onAuth(event.email, event.password, true)}
                    initialValues={initialValues}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit} className='centered-form'>
                            <Form.Group>
                                <Form.Label className={'navlinks_' + theme.theme}>Email</Form.Label>
                                <Form.Control
                                    className={'navlinks_' + theme.theme}
                                    required
                                    size='sm'
                                    type='text'
                                    name='email'
                                    placeholder='Email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && errors.email}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size='sm'>
                                <Form.Label className={'navlinks_' + theme.theme}>Password</Form.Label>
                                <Form.Control
                                    className={'navlinks_' + theme.theme}
                                    required
                                    size='sm'
                                    type='password'
                                    name='password'
                                    placeholder='Password'
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={touched.password && errors.password}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                            </Form.Group>
                            <Button size='sm' type='submit'>
                                Login
                            </Button>{' '}
                            <LinkContainer to='/forgotpassword'>
                                <Button size='sm' variant='link'>
                                    Forgot Password
                                </Button>
                            </LinkContainer>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password, isLogin) => dispatch(actions.auth(email, password, isLogin)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
