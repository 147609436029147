import React from 'react'
import { Center } from '../../buildingBlocks/PageLayout/Centering/Center'
import { Paragraph } from '../../buildingBlocks/Text/Paragraph'

export const Countdown = ({ seconds, setGamePhase }) => {
    const [timer, setTimer] = React.useState(seconds)

    React.useEffect(() => {
        const countdown = (timer) => {
            if (timer > 0) {
                setTimeout(() => {
                    setTimer(timer - 1)
                    countdown(timer - 1)
                }, 1000)
            } else {
                setGamePhase('gameInProgress')
            }
        }
        countdown(timer)
    }, [setGamePhase, timer])

    return (
        <Center horizontalOverride={'42%'} verticalOverride={'45%'}>
            <Paragraph fontSize={'2rem'}>Game starting in {timer}</Paragraph>
        </Center>
    )
}
