import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function SolveStatusModal(props) {
    return (
        <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You will lose your progress on your current puzzle. Are you sure you want to continue?
            </Modal.Body>
            <Modal.Footer>
                {props.proceedButton}
                <Button onClick={props.onHide} variant='success'>
                    Oh, nevermind then
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
