import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { BrowserRouter } from 'react-router-dom'
import countReducer from './store/reducers/counter'
import resultsReducer from './store/reducers/results'
import authReducer from './store/reducers/auth'
import notistackReducer from './store/reducers/notistack'
import puzzleReducer from './store/reducers/puzzle'
import { SnackbarProvider } from 'notistack'
import './fonts/Rubik-Regular.ttf'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from 'styled-components'
import { ThemeManager } from './theme/themeManager'

export const theme = new ThemeManager()

const rootReducer = combineReducers({
    counter: countReducer,
    results: resultsReducer,
    auth: authReducer,
    notifications: notistackReducer,
    puzzle: puzzleReducer,
})

const logger = (store) => {
    return (next) => {
        return (action) => {
            // console.log('[Middleware] Dispatching ', action)
            const result = next(action)
            // console.log('[Middleware] next state', store.getState())
            return result
        }
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)))

const queryClient = new QueryClient()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <SnackbarProvider>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                            <App />
                        </ThemeProvider>
                    </QueryClientProvider>
                </SnackbarProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
