import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'

const SizeSelector = (props) => {
    const isLevelComplete = (level) => {
        const difficultiesCompleted = Object.values(props.scorecard[level])
        return !difficultiesCompleted.includes(false)
    }

    return (
        <ButtonGroup>
            <Button
                variant={isLevelComplete(3) ? 'success' : 'primary'}
                active={3 === props.selectedSize}
                onClick={() => props.handleClick(3)}>
                {isLevelComplete(3) ? '✔️' : '1'}
            </Button>
            <Button
                active={4 === props.selectedSize}
                onClick={() => props.handleClick(4)}
                variant={isLevelComplete(4) ? 'success' : 'primary'}>
                {isLevelComplete(4) ? '✔️' : '2'}
            </Button>
            <Button
                active={5 === props.selectedSize}
                onClick={() => props.handleClick(5)}
                variant={isLevelComplete(5) ? 'success' : 'primary'}>
                {isLevelComplete(5) ? '✔️' : '3'}
            </Button>
            <Button
                active={6 === props.selectedSize}
                onClick={() => props.handleClick(6)}
                variant={isLevelComplete(6) ? 'success' : 'primary'}>
                {isLevelComplete(6) ? '✔️' : '4'}
            </Button>
            <Button
                active={7 === props.selectedSize}
                onClick={() => props.handleClick(7)}
                variant={isLevelComplete(7) ? 'success' : 'primary'}>
                {isLevelComplete(7) ? '✔️' : '5'}
            </Button>
            <Button
                active={8 === props.selectedSize}
                onClick={() => props.handleClick(8)}
                variant={isLevelComplete(8) ? 'success' : 'primary'}>
                {isLevelComplete(8) ? '✔️' : '6'}
            </Button>
            <Button
                active={9 === props.selectedSize}
                onClick={() => props.handleClick(9)}
                variant={isLevelComplete(9) ? 'success' : 'primary'}>
                {isLevelComplete(9) ? '✔️' : '7'}
            </Button>
            <Button
                active={10 === props.selectedSize}
                onClick={() => props.handleClick(10)}
                variant={isLevelComplete(10) ? 'success' : 'primary'}>
                {isLevelComplete(10) ? '✔️' : '8'}
            </Button>
        </ButtonGroup>
    )
}

export default SizeSelector
