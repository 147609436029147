import React, { useContext, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Center } from '../../buildingBlocks/PageLayout/Centering/Center'
import { ThemeContext } from '../../App'
import { useHistory } from 'react-router-dom'
import { api } from '../../axios/axios'

export const JoinGame = () => {
    const theme = useContext(ThemeContext)
    const [roomNumber, setRoomNumber] = useState('')
    const [joinError, setJoinError] = useState('')
    const history = useHistory()

    const isRoomJoinable = async () => {
        const { data } = await api.get(`rooms/isRoomJoinable/${roomNumber}`)
        return data.data
    }

    const handleSubmit = async () => {
        const response = await isRoomJoinable()
        if (!response.isJoinable) {
            setJoinError(response.message)
        } else {
            history.push(`/multiplayer/game/${roomNumber.trim()}`)
        }
    }

    const handleChange = (e) => {
        setRoomNumber(e.target.value)
    }

    return (
        <>
            <Row>
                <Col md={3}></Col>
                <Col md={6}>
                    <h1 className={'navlinks_' + theme.theme}>multiplayer</h1>
                </Col>
                <Col md={3}></Col>
            </Row>
            <Center>
                <Form className='centered-form'>
                    <Form.Group>
                        <Form.Label className={'navlinks_' + theme.theme}>Enter Room Name</Form.Label>
                        <Form.Control
                            className={'navlinks_' + theme.theme}
                            required
                            size='sm'
                            name='gameId'
                            placeholder='Room name'
                            value={roomNumber}
                            onChange={handleChange}
                            isInvalid={Boolean(joinError)}
                        />
                        <Form.Control.Feedback type='invalid'>{joinError}</Form.Control.Feedback>
                        <Button size='sm' style={{ marginTop: '10px' }} onClick={handleSubmit}>
                            Join
                        </Button>
                    </Form.Group>
                </Form>
            </Center>
        </>
    )
}
