import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actionTypes from '../../../store/actions/index'
import { Redirect } from 'react-router-dom'

const Logout = ({ onLogout }) => {
    useEffect(() => {
        onLogout()
    }, [onLogout])

    return <Redirect to='/' />
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            dispatch(actionTypes.logoutHandler())
        },
    }
}

export default connect(null, mapDispatchToProps)(Logout)
