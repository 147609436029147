import React, { useContext } from 'react'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import * as yup from 'yup'
import { isUsernameTaken, doesEmailExist } from '../../../firebase/tdg'
import { ThemeContext } from '../../../App'

const initialValues = { email: '', password: '', confirmPassword: '', username: '' }
const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required('Please enter an email.')
        .test('Check if email exists', 'An account with this email already exists.', function (value) {
            if (!value) {
                return false
            } else {
                return doesEmailExist(value)
            }
        }),
    password: yup
        .string()
        .min(5, 'Password must be at least 5 characters')
        .max(50, 'Password must be at most 50 characters')
        .required('Please enter a password'),
    confirmPassword: yup
        .string()
        .required('Please confirm password')
        .when('password', {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref('password')], 'Both passwords need to be the same'),
        }),
    username: yup
        .string()
        .required()
        .min(5, 'Username must be at least 5 characters')
        .max(30, 'Username cannot be longer than 50 characters')
        .test('test-name', 'Username is already taken', function (value) {
            if (!value) {
                return false
            } else {
                return isUsernameTaken(value)
            }
        }),
})

const Register = ({ onAuth, error }) => {
    const theme = useContext(ThemeContext)

    return (
        <div className='horizontal-center'>
            <div className='vertical-center'>
                <Formik
                    validationSchema={validationSchema}
                    onSubmit={(event) => onAuth(event.email, event.password, false, event.username)}
                    initialValues={initialValues}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit} className='centered-form'>
                            <Form.Group>
                                <Form.Label className={'navlinks_' + theme.theme}>Email</Form.Label>
                                <Form.Control
                                    className={'navlinks_' + theme.theme}
                                    required
                                    size='sm'
                                    type='text'
                                    name='email'
                                    placeholder='Email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && errors.email}
                                />
                                <Form.Control.Feedback type='invalid' className={'navlinks_' + theme.theme}>
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size='sm'>
                                <Form.Label className={'navlinks_' + theme.theme}>Password</Form.Label>
                                <Form.Control
                                    className={'navlinks_' + theme.theme}
                                    required
                                    size='sm'
                                    type='password'
                                    name='password'
                                    placeholder='Password'
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={touched.password && errors.password}
                                />
                                <Form.Control.Feedback type='invalid' className={'navlinks_' + theme.theme}>
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size='sm'>
                                <Form.Label className={'navlinks_' + theme.theme}>Confirm Password</Form.Label>
                                <Form.Control
                                    className={'navlinks_' + theme.theme}
                                    required
                                    size='sm'
                                    type='password'
                                    name='confirmPassword'
                                    placeholder='Confirm Password'
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.confirmPassword && !errors.confirmPassword}
                                    isInvalid={touched.confirmPassword && errors.confirmPassword}
                                />
                                <Form.Control.Feedback type='invalid' className={'navlinks_' + theme.theme}>
                                    {errors.confirmPassword}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size='sm'>
                                <Form.Label className={'navlinks_' + theme.theme}>Username</Form.Label>
                                <Form.Control
                                    className={'navlinks_' + theme.theme}
                                    required
                                    size='sm'
                                    type='text'
                                    name='username'
                                    placeholder='Username'
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.username && !errors.username}
                                    isInvalid={touched.username && errors.username}
                                />
                                <Form.Control.Feedback type='invalid' className={'navlinks_' + theme.theme}>
                                    {errors.username}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button size='sm' type='submit'>
                                Sign Up
                            </Button>
                        </Form>
                    )}
                </Formik>
                console.log(Button_)
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password, isLogin, username) => dispatch(actions.auth(email, password, isLogin, username)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
