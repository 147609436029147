import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    email: null,
    username: null,
    resetPasswordConfirmation: null,
    wasRedirected: false,
}

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true })
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        error: null,
        loading: false,
        email: action.email,
        username: action.username,
    })
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null,
        error: null,
        loading: false,
        email: null,
        username: null,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action)
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action)
        case actionTypes.AUTH_FAIL:
            return authFail(state, action)
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action)
        case actionTypes.UPDATE_USERNAME:
            return updateObject(state, { username: action.username })
        case actionTypes.RESET_PASSWORD_CONFIRMATION_EMAIL:
            return updateObject(state, { resetPasswordConfirmation: action.email })
        case actionTypes.UPDATE_WAS_REDIRECTED:
            return updateObject(state, { wasRedirected: action.wasRedirected })
        default:
            return state
    }
}

export default reducer
