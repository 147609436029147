import React, { useEffect, useState, createContext } from 'react'
import './App.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Login from './features/Authentication/Login/Login'
import Register from './features/Authentication/Register/Register'
import Logout from './features/Authentication/Logout/Logout'
import ForgotPassword from './features/Authentication/ForgotPassword/ForgotPassword'
import Leaderboards from './features/Leaderboards/Leaderboards'
import Home from './features/LandingPage/Home'
import Puzzle from './features/Puzzle/Container/Puzzle'
import Layout from './features/Navigation/Layout/Layout'
import * as actions from './store/actions/index'
import Notifier from './features/Notifications/Notifier'
import { Route, Redirect, Switch } from 'react-router-dom'
import { PrivateGame } from './features/Multiplayer/PrivateGame'
import PublicGame from './features/Multiplayer/PublicGame'
import { Multiplayer } from './features/Multiplayer/Multiplayer'
import Game from './features/Multiplayer/Game'
import { JoinGame } from './features/Multiplayer/JoinGame'
import OnlineGame from './features/Multiplayer/OnlineGame'

export const ThemeToggleContext = createContext({})
export const ThemeContext = createContext({})

const App = ({ isAuthenticated, onTryAutoSignup }) => {
    useEffect(() => {
        onTryAutoSignup()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let routes = (
        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/puzzle' exact component={Puzzle} />
            <Route path='/leaderboards' exact component={Leaderboards} />
            <Route path='/auth' component={Login} />
            <Route path='/register' component={Register} />
            <Route path='/forgotpassword' component={ForgotPassword} />
            <Redirect to='/' />
        </Switch>
    )

    if (isAuthenticated) {
        routes = (
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/puzzle' exact component={Puzzle} />
                <Route path='/leaderboards' exact component={Leaderboards} />
                <Route path='/multiplayer' exact component={Multiplayer} />
                <Route path='/multiplayer/privateGame' exact component={PrivateGame} />
                <Route path='/multiplayer/publicGame' exact component={PublicGame} />
                <Route path='/multiplayer/joinGame' exact component={JoinGame} />
                <Route path='/multiplayer/game/:id' children={<Game />} />
                <Route path='/multiplayer/onlineGame/:id' children={<OnlineGame />} />
                <Route path='/logout' component={Logout} />
                <Redirect to='/' />
            </Switch>
        )
    }

    const [theme, setTheme] = useState('dark')

    if (theme === 'dark') {
        document.body.style.backgroundColor = '#182952'
    } else {
        document.body.style.backgroundColor = 'white'
    }

    return (
        <div>
            <Notifier />
            <ThemeToggleContext.Provider
                value={{
                    setTheme,
                }}>
                <ThemeContext.Provider
                    value={{
                        theme,
                    }}>
                    <Layout isLoggedIn={isAuthenticated}>{routes}</Layout>
                </ThemeContext.Provider>
            </ThemeToggleContext.Provider>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        wasLoginError: state.auth.error,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
