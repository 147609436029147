import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function SolveStatusModal(props) {
    const get_p_element = (text) => {
        return <p>{text}</p>
    }

    const get_h4_element = (title) => {
        return <h4>{title}</h4>
    }

    const get_strat_status = (key) => {
        return [get_h4_element(key), get_p_element(props.solveStatus[key]['pretty_status'])]
    }

    const solvingStatuses = Object.keys(props.solveStatus)
        .map((key) => {
            return get_strat_status(key)
        })
        .reduce((accumulator, el) => accumulator.concat(el), [])

    return (
        <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>Solve Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>{solvingStatuses}</Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
