import React from 'react'

export const Center = ({ children, horizontalOverride, verticalOverride }) => {
    let horizontalProps = {}
    if (horizontalOverride) {
        horizontalProps = {
            style: {
                left: horizontalOverride,
            },
        }
    }
    let verticalProps = {}
    if (verticalOverride) {
        verticalProps = {
            style: {
                top: verticalOverride,
            },
        }
    }

    return (
        <div className='horizontal-center' {...horizontalProps}>
            <div className='vertical-center' {...verticalProps}>
                {children}
            </div>
        </div>
    )
}
