import React, { useContext } from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { ThemeContext } from '../../../App'

const AccountNavigation = (props) => {
    const theme = useContext(ThemeContext)

    return (
        <div>
            {props.isLoggedIn ? (
                <Nav className='mr-auto'>
                    <LinkContainer to='/logout'>
                        <Nav.Link className='logout-warning-mobile' href='/logout'>
                            <span className={'navlinks_' + theme.theme + ' hvr-underline-from-left'}>Logout</span>
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
            ) : (
                <Nav className='mr-auto'>
                    <LinkContainer to='/auth'>
                        <Nav.Link>
                            <span className={'navlinks_' + theme.theme + ' hvr-underline-from-left'}>Login</span>
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/register'>
                        <Nav.Link>
                            <span className={'navlinks_' + theme.theme + ' hvr-underline-from-left'}>Sign Up</span>
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
            )}
        </div>
    )
}

export default AccountNavigation
