export class ThemeManager {
    constructor() {
        this.availableColorPalettes = [
            {
                COLOR1: '#e14594',
                COLOR2: '#2b3595',
                COLOR3: '#182952',
                COLOR4: '#222A77',
                COLOR5: '#7045af',
                COLOR6: '#5a378c',
            },
            {
                COLOR1: '#F15025',
                COLOR2: '#FFFFFF',
                COLOR3: '#E6E8E6',
                COLOR4: '#CED0CE',
                COLOR5: '#191919',
                COLOR6: '#5a378c',
            },
            {
                COLOR1: '#EFD9CE',
                COLOR2: '#DEC0F1',
                COLOR3: '#B79CED',
                COLOR4: '#957FEF',
                COLOR5: '#7161EF',
                COLOR6: '#5a378c',
            },
            {
                COLOR1: '#011627',
                COLOR2: '#FF3366',
                COLOR3: '#2EC4B6',
                COLOR4: '#F6F7F8',
                COLOR5: '#20A4F3',
                COLOR6: '#5a378c',
            },
        ]
        this.currentColorPalette = {
            COLOR1: '#e14594',
            COLOR2: '#2b3595',
            COLOR3: '#182952',
            COLOR4: '#222A77',
            COLOR5: '#7045af',
            COLOR6: '#5a378c',
        }
        this.currentTheme = {
            primary: this.currentColorPalette.COLOR1,
            secondary: this.currentColorPalette.COLOR2,
            background: this.currentColorPalette.COLOR3,
            button: {
                main: this.currentColorPalette.COLOR2,
                mainHover: this.currentColorPalette.COLOR4,
                completed: this.currentColorPalette.COLOR5,
                completedHover: this.currentColorPalette.COLOR6,
            },
        }
    }

    setTheme(themeNumber) {
        console.log(this.currentColorPalette)
        this.currentColorPalette = this.availableColorPalettes[themeNumber]
        console.log(this.currentColorPalette)
    }
}
